export default {
    data: function(){
        return {
            optionLangs: [
                {
                text: 'Vietnamese',
                value: 'vn'
                },
                {
                text: 'English',
                value: 'en'
                }
            ]
        };
    },
    methods: {
      callSetLangActions (event) {
        this.$store.dispatch('lang/setLang', event.target.getAttribute('value'))
      }
    }
}